<template>
    <div class="basic-map mobile">
        <l-map
            ref="map"
            :center="mapCenter"
            :zoom="mapZoom"
            :max-zoom="maxZoom"
        >
            <l-tile-layer
                :url="url"
                layer-type="base"
                name="OpenStreetMap"
                :attribution="attribution"
            ></l-tile-layer>

            <l-marker v-if="displayIcon" :lat-lng="mapCenter">
                <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
            </l-marker>
        </l-map>
    </div>
</template>

<script>
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet"
import L from "leaflet"

export default {
    name: "BasicMap",
    props: {
        mapCenter: {
            type: Object,
            default: function () {
                return { lat: 46.915158, lng: 2.999381 }
            },
            required: true,
        },
        mapZoom: {
            type: Number,
            default: 5,
            required: true,
        },
        displayIcon: {
            type: Boolean,
            default: false,
            required: false,
        },
        iconUrl: {
            type: String,
            default: "",
            required: false,
        },
    },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
    },
    computed: {
        iconSize() {
            return [30, 30]
        },
    },
    data() {
        return {
            maxZoom: 16,
            url: "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
            attribution: "",
            icon_default: L.divIcon({
                className: "marker-default",
                iconSize: [38, 38],
                iconAnchor: [19, 38],
                popupAnchor: [0, -15],
            }),
        }
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
.leaflet-bottom {
    display: none;
}
.leaflet-pane {
    z-index: 20 !important;
}

.leaflet-control-zoom,
.leaflet-top,
.leaflet-left {
    z-index: 20 !important; /* Retire explicitement le z-index */
}

.basic-map {
    overflow: hidden;
    height: 360px;
    width: 520px;
    border: $blue-neutral solid 1px;

    //@media #{$mq_phone} {
    //    height: 300px;
    //    margin-bottom: 50px;
    //}
}
</style>
