<template>
    <section class="basicLink">
        <span
            :style="{
                color: '#12385B',
                textDecoration: `underline ${color}`
            }"
        >
            {{ text }}
        </span>
    </section>
</template>

<script>
export default {
    name: "BasicLink",
    props: {
        text: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: '#DF6C15',
            required: false
        }
    }
}
</script>

<style scoped>
.basicLink {
    cursor: pointer;
}
</style>
